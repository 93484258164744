import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

import { AuthContext } from "../context/authContext";

axios.defaults.baseURL = "https://api.wedesignwecode.com/api/"
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Cache-Control"] = "no-store";

const Home = () => {
const state = useLocation().state;
const [posts, setPosts] = useState([]);
const cat = useLocation().search
const [value, setValue] = useState(state?.title || "");
const [title, setTitle] = useState(state?.desc || "");
const navigate = useNavigate()

useEffect(() => {
  const fetchData = async () => {
    try {
      const res = await axios.get(`/posts/${cat}`);
      console.log(res);
      setPosts(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  fetchData();
}, [cat]);


const [single, setPost] = useState({});
const postId = 1;
useEffect(() => {
  const fetchData = async () => {
    try {
      const res = await axios.get(`/posts${cat}`);
      setPost(res.data[0].title);
    } catch (err) {
      console.log(err);
    }
  };
  fetchData();
}, [1]);

const getText = (html) =>{
  const doc = new DOMParser().parseFromString(html, "text/html")
  return doc.body.textContent
}

const handleClick = async (e) => {
  e.preventDefault();
  
  try {
    state
      ? await axios.put(`/posts/1`, {
          title,
          desc: value,
        })
      : await axios.post(`/posts/`, {
          title,
          desc: value,
        });
  } catch (err) {
    console.log(err);
  }
  
  navigate("/")
};


const { currentUser, logout } = useContext(AuthContext);

  if (!currentUser)
  return <Navigate to='/login'  /> 

  if (currentUser)
  return (

    <div className="home">
      <div className="posts">

        {posts.map((post) => (
          <div className="post" key={post.id}>
          <Link className="link" state={post} to={`/write?edit=${post.id}`}>
            <div className="img">              
              <img src={`https://api.wedesignwecode.com/upload/${post.img}`} alt="" />   
            </div>
            </Link>   
          </div>
        ))}


      </div>
    </div>
  );
};

export default Home;
