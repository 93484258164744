import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../context/authContext";
import Logo from "../img/logo.png";
import { useState } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";


const Navbar = () => {
const { currentUser, logout } = useContext(AuthContext);
const cat = useLocation().search
const [single, setPost] = useState({});
const postId = 1;
useEffect(() => {
  const fetchData = async () => {
    try {
      const res = await axios.get(`/posts${cat}`);
      setPost(res.data[0].title);
    } catch (err) {
      console.log(err);
    }
  };
  fetchData();
}, [1]);

  return (
    <div className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/">
          <img src={Logo} alt="" />
          </Link>
        </div>
        <div className="links">
          
          <Link className="link" to="/">
          Forside
          </Link>

          <span className="write">
            <Link className="link" to="/add">
            Tilføj ny avis
            </Link>
          </span>
        
          {currentUser ? (
            <span onClick={logout}>Logud</span>
          ) : (
            <Link className="link" to="/login">
              Logind
            </Link>
          )}
       
        </div>
      </div>
    </div>
  );
};

export default Navbar;
