import React, { useEffect,useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useContext } from "react";
import { useLocation, useNavigate,Navigate } from "react-router-dom";
import moment from "moment";
import Delete from "../img/delete.png";
import { AuthContext } from "../context/authContext";
axios.defaults.baseURL = "https://api.wedesignwecode.com/api/"

const Write = () => {
  const state = useLocation().state;
  const [value, setValue] = useState(state?.title || "");
  const [title, setTitle] = useState(state?.desc || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || "");
  const location = useLocation();
  const [post, setPost] = useState({});
  const navigate = useNavigate()

  const upload = async () => {
      const formData = new FormData();   
      
      if ( file ){     
        formData.append("file", file);
        const res = await axios.post("/upload", formData);
        return res.data;
      }
   
  };
  const postId = state?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/posts/${postId}`);
        setPost(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async ()=>{
    try {
      await axios.delete(`/posts/${postId}`);
      navigate("/")
    } catch (err) {
      console.log(err);
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    const imgUrl = await upload();

    try {
      state
      ? await axios.put(`/posts/${state.id}`, {
        title,
        desc: value,
        cat,
        img: file ? imgUrl : state.img,
          })
          : await axios.post(`/posts/`, {
            title,
            desc: value,
            cat,
            img: file ? imgUrl : state.img,
            date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          });
          navigate("/")
    } catch (err) {
      console.log(err);
    }
  };

  const { currentUser, logout } = useContext(AuthContext);
  axios.defaults.headers.common['Authorization'] = `${currentUser.access_token}`;

  if (!currentUser)
  return <Navigate to='/login'  /> 

  if (currentUser)
  return (
    <div className="add">
      <div className="content">
        <input
          type="text"
          placeholder="Title"
          defaultValue={state?.title}
          onChange={(e) => setTitle(e.target.value)}
        />

      <div className="imgarea">
        <img className="imgPanel" src={`https://api.wedesignwecode.com/upload/${state?.img}`} alt="" />
       <br/><br/> <img onClick={handleDelete} src={Delete} alt="" />
      </div>
      
      </div>
      <div className="menu">
        <div className="item">
          <h1>Update</h1>
     
          <input
            style={{ display: "none" }}
            type="file"
            id="file"
            name=""
            onChange={(e) => setFile(e.target.files[0])}
          />

          <label className="file" htmlFor="file">
            Skift Billede
          </label>
     
          <div className="buttons">
            
            <button onClick={handleClick}>Update</button>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Write;