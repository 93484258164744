import React from "react";

const Footer = () => {
  return (
    <footer>
      <span>
        Copyright <b>wdwc.dk</b>
      </span>
    </footer>
  );
};

export default Footer;
